import React from "react";
import Button from "~/components/button";
import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";
import LessonLayout from "~/layouts/lesson";

const Page = () => {
  return (
    <LessonLayout
      current_step={1}
      lesson={"Lesson 5"}
      color={"ms"}
      total_count={7}
      module_title="Putting Your Rubric to Work"
    >
      <ContentWrapper>
        <H2 underline>Step 1</H2>

        <p>When you started this unit, you were given the challenge of developing a rubric that could help other people evaluate health-related information. Before you give it to someone else, you should test it out.</p>
        <p>To begin testing your rubric, take another look at the 3 websites about CBD that you have been evaluating.</p>

        <div className="flex flex-wrap flex-col md:flex-row md:flex-nowrap place-content-between">
          <Button
            action="secondary"
            className="md:mt-2"
            color="purple"
            href="/article/cbd/cbd-now"
            icon="external"
            title="CBD website 1"
          >
            CBD website 1
          </Button>
          <Button
            action="secondary"
            className="mt-2"
            color="purple"
            href="/article/cbd/cbd-truth"
            icon="external"
            title="CBD website 2"
          >
            CBD website 2
          </Button>
          <Button
            action="secondary"
            className="mt-2"
            color="purple"
            href="/article/cbd/cansativa-organics"
            icon="external"
            title="CBD website 3"
          >
            CBD website 3
          </Button>
        </div>

        <p>Do you want to make any changes to the scores on your rubric?</p>
        <p>When you are happy with your scores, add up your final score for each one. How do your scores compare with your ideas when you first looked at these sites in Lesson 1?</p>
        <p>Do you feel that you are better able to answer the original question about CBD, "Would medical professionals think CBD is a good way for people to deal with long-term pain?" Why or why not?</p>
      </ContentWrapper>
    </LessonLayout >
  )
}

export default Page;

